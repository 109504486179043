import { useEffect } from 'react';

import { destroyCookie } from 'nookies';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAuth, useQueryParam } from '~/hooks';
import { LoadingPage } from '~/theme/components';

function SignOut() {
	const { isUserIn, signOut } = useAuth();
	const { redirect } = useQueryParam('redirect');
	const navigate = useNavigate();

	useEffect(() => {
		signOut();
		for (const key of ['token', 'role']) destroyCookie(undefined, key, { domain: import.meta.env.VITE_APP_DOMAIN as string });

		return () => {
			navigate(0);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	if (!isUserIn) return <Navigate to={redirect ? `/signin/?product=${redirect}` : '/signin/'} />;

	return <LoadingPage />;
}

export default SignOut;
