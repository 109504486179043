import { useEffect, useRef } from 'react';

import { Box, Button, FormControl, FormLabel, Flex, FormErrorMessage, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import slice from '~/context/features/auth';
import { useTitle } from '~/hooks';
import useDispatch from '~/hooks/useDispatch';
import useNamespace from '~/hooks/useNamespace';
import { Alert, ImperativeAlertProps, Password } from '~/theme/components';
import { ArrowLeft } from '~/theme/icons';
import { yupTest } from '~/utils/passVerify';

import { useLoggedOut } from '../Layout';

export interface ProvisionalFormProps {
	password: string;
	confirmPassword: string;
}

const schema = Yup.object({
	password: Yup.string()
		.required('provisional@validation-password-required')
		.test(...yupTest('provisional')),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'provisional@validation-confirm-password-invalid')
		.required('provisional@validation-confirm-password-required'),
}).required();

function Provisional() {
	const { auth, goBack, ...product } = useLoggedOut();
	const { translate } = useNamespace('provisional');
	const dispatch = useDispatch();
	const alertRef = useRef<ImperativeAlertProps>(null);

	const { formState, register, handleSubmit } = useForm<ProvisionalFormProps>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		mode: 'all',
	});

	const onSubmit: SubmitHandler<ProvisionalFormProps> = async ({ password }) => {
		auth.completePassword(password);
	};

	useEffect(() => {
		dispatch(slice.actions.setAuthState(null));
	}, [dispatch]);

	useTitle(`${translate('provisional@title')} | ${product.name}`);

	return (
		<>
			<Stack alignSelf="flex-start" alignItems="flex-start">
				<Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
					{translate('provisional@go-back-button')}
				</Button>
				<Box textStyle="h5" color="gray.900">
					{translate('provisional@provisional-password-title')}
				</Box>
			</Stack>
			{auth.error && (
				<Alert status="error" ref={alertRef}>
					{auth.error?.message}
				</Alert>
			)}
			<FormControl
				display="flex"
				flexDirection="column"
				width="100%"
				gridRowGap={['4', '8']}
				as="form"
				isInvalid={formState.isDirty}
			>
				<Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
					<Flex direction="column" width="inherit">
						<FormLabel htmlFor="password" textStyle="label.primary" color="black">
							{translate('provisional@form-password-label')}
						</FormLabel>
						<Password
							id="password"
							data-testid="password"
							isInvalid={!!formState.errors.password}
							required
							{...register('password')}
							hint
						/>
						{/* {formState.errors.password && (
              <FormErrorMessage>{translate(formState.errors.password.message as string)}</FormErrorMessage>
						)} */}
					</Flex>

					<Flex direction="column" width="inherit">
						<FormLabel htmlFor="confirmPassword" textStyle="label.primary" color="black">
							{translate('provisional@form-confirm-password-label')}
						</FormLabel>
						<Password
							id="confirmPassword"
							data-testid="confirmPassword"
							isInvalid={!!formState.errors.confirmPassword}
							required
							{...register('confirmPassword')}
						/>
						{formState.errors.confirmPassword && (
							<FormErrorMessage>
								{translate(formState.errors.confirmPassword.message as 'provisional@validation-confirm-password-invalid')}
							</FormErrorMessage>
						)}
					</Flex>
				</Flex>
				<Button
					type="submit"
					onClick={handleSubmit(onSubmit)}
					isDisabled={!formState.isValid}
					isLoading={formState.isSubmitted}
					data-testid="submit"
					data-type="primary"
					isFullWidth
				>
					{translate('provisional@form-change-password-button')}
				</Button>
			</FormControl>
		</>
	);
}

export default Provisional;

// const onSubmit: SubmitHandler<ProvisionalFormProps> = async ({ password }) => {
//   setLoading(true);
//   auth.provisional?.user.completeNewPasswordChallenge(password, auth.provisional?.userAttributes, {
//     onSuccess: () => {
//       auth.signIn(auth.provisional?.userAttributes.email as string, password, product?.callback_url);
//     },
//     onFailure: (err) => {
//       setCompletePwdError(err);
//       setLoading(false);
//     },
//   });
// };
