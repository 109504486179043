import { Box, Flex, Grid, GridItem, GridProps, Image, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { ResponseProps as UserProps, useUser } from '~/api/users/retrieve';
import { useAuth, useQueryParam } from '~/hooks';
import useNamespace from '~/hooks/useNamespace';
import theme from '~/theme';
import { LoadingPage, SupportButton } from '~/theme/components';
import { Password, User, Home, AddressBook, Talks, F } from '~/theme/icons';

import { NavItem, RightSideBar, Navigation, Header } from './components';

const navItems = [
	{ Icon: User, title: 'pageLayout@config-personal-data-title', to: '/' },
	{ Icon: Password, title: 'pageLayout@config-password-title', to: '/password' },
	{ Icon: Home, title: 'pageLayout@config-address-title', to: '/address' },
	{ Icon: AddressBook, title: 'pageLayout@config-contact-title', to: '/contact' },
];

const products = [
	{ Icon: Talks, title: 'Talks', to: `https://talks${import.meta.env.VITE_APP_DOMAIN}/student/profile`, key: 'talks_talks' },
	{ Icon: F, title: 'Academy', to: `https://academy${import.meta.env.VITE_APP_DOMAIN}`, key: 'fluency_academy' },
];

function Layout() {
	const auth = useAuth();
	const params = useQueryParam('product');
	const { data } = useUser();
	const { onOpen, isOpen, onClose } = useDisclosure();
	const { translate } = useNamespace('pageLayout');

	const responsive = useBreakpointValue<GridProps>([
		{
			px: '4',
			pb: '8',
			pt: '28',
			gridRowGap: '6',
			gridTemplateColumns: '1fr',
			gridTemplateRows: '0 auto 1fr',
			gridTemplateAreas: `
				'navigation'
				'profile'
				'content'
			`,
		},
		{
			px: 'auto',
			pb: 'auto',
			mt: '16',
			gridColumnGap: '6',
			gridTemplateColumns: `${theme.sizes[80]} ${theme.sizes.xl} ${theme.sizes[56]}`,
			gridTemplateRows: '1fr',
			gridTemplateAreas: `
				'navigation content profile'
			`,
		},
	]);

	// If the user is authenticated and the product is defined, show the loader to redirect the user to the product
	if (auth.isUserIn && params.product) {
		return <LoadingPage />;
	}

	return (
		<Flex bgColor="gray.100" position="relative" width="100%" minHeight="100vh" justifyContent="center">
			<Header onOpen={onOpen} />
			<Grid
				justifyContent="center"
				position="relative"
				zIndex="docked"
				sx={{
					'& .card': {
						borderRadius: '2xl',
						bgColor: 'white',
						borderWidth: '0.0625rem',
						borderColor: 'purple.50',
						borderStyle: 'solid',
					},
				}}
				{...responsive}
			>
				<GridItem gridArea="navigation">
					<Navigation isOpen={isOpen} onClose={onClose}>
						{(isMobileDevice) => (
							<>
								{isMobileDevice ? (
									(isOpen ? products : navItems).map((props) => <NavItem key={props.to} isMobile={isMobileDevice} {...props} />)
								) : (
									<>
										<Box px="8" textStyle="h6">
											{translate('pageLayout@config-title')}
										</Box>
										<Flex width="inherit" direction="column">
											{navItems.map(({ title, ...props }) => (
												<NavItem
													tabIndex={0}
													key={props.to}
													isMobile={isMobileDevice}
													title={translate(title as 'pageLayout@config-title')}
													{...props}
												/>
											))}
										</Flex>
										<Box px="8" textStyle="h6">
											{translate('pageLayout@products-title')}
										</Box>
										<Flex width="inherit" direction="column">
											{products.map((product, index) => (
												<NavItem
													key={product.key}
													tabIndex={index}
													isMobile={false}
													Icon={product.Icon}
													title={product.title}
													to={product.to}
												/>
											))}
										</Flex>
									</>
								)}
							</>
						)}
					</Navigation>
				</GridItem>
				<GridItem gridArea="content">
					<Flex display="flex" className="card" padding="8" mt="12" width="100%">
						<Outlet context={{ auth, user: data }} />
					</Flex>
				</GridItem>
				<GridItem gridArea="profile">
					<RightSideBar user={data} />
				</GridItem>
			</Grid>
			<Image inset="0 0 auto auto" position="fixed" width="sm" height="3xl" src="/images/fluency.svg" />
			<SupportButton />
		</Flex>
	);
}

export function useSignedIn() {
	const response = useOutletContext<{ auth: ReturnType<typeof useAuth>; user: UserProps }>();

	return response;
}

export default Layout;
